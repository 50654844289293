import React from "react";
import styles from './Product.module.css';
import { useNavigate } from 'react-router-dom';

function Product( { product } ) {
  
  const navigate = useNavigate();

  const clickHandle = () => {
    navigate(`/shop/${product.product_name}/id-${product.product_id}`)
    window.scrollTo(0, 0);
  }

  return (
    <div className={styles.container} onClick={clickHandle} >
      <div className={styles['image-container']} >
        <img className={styles.image} src={product.image} alt='image_product' />
        <div className={styles.brand} >Brand</div>
      </div>
      <div style={{display: 'flex', margin: '0px 8px 0px 8px', justifyContent: 'space-between' }}>
        <div >
          {product.size_color_qty.size_color_qty.map((item) => {
            return (
              <>{item.color}</>
            );
          })}
        </div>
        <div className={styles.attributes} >price: {product.price}</div>
      </div>
      <div className={styles.attributes} style={{fontWeight: 'bold', textAlign: 'left', marginLeft: '8px' }} >{product.product_name}</div>
    </div>
  );
}

export default Product;