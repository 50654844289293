import React, { useEffect, useContext, useRef, useState } from "react";
import styles from './Shop.module.css';
import { useSearchParams } from 'react-router-dom';
import PageNavigator from '../components/Shop/PageNavigator';
import Products from '../components/Shop/Products';
import ShopSidebar from '../components/Shop/ShopSidebar';
import { listProducts, countProducts } from '../utils.js/api';
import animation from '../image/animation-loading.gif';
import { useMediaQuery } from 'react-responsive';
import { Collapse } from "react-burgers";
import { NavVisibleContext } from '../context/AppContext';
import OrderOptions from "../components/Shop/OrderOptions";

function Shop() {

  const { navVisible } = useContext(NavVisibleContext);

  const [queryParams, setQueryParams] = useSearchParams({});

  const page = Number(queryParams.get('page'))
  const [pageLimit, setPageLimit] = useState(50);
  const [products, setProducts] = useState([]);
  const [count, setCount] = useState(null);
  const [animationLoading, setAnimationLoading] = useState(true);

  useEffect(() => { // this the only thing causing more than 2 rerenders per page change see console.log
    window.scrollTo(0, 0);
    setAnimationLoading(true);
    listProducts(window.location.search) // each query in the query string must be formatted ?databaseColumnName=desiredRowValue
      .then(setProducts)
      .then(() => setAnimationLoading(false))
      .then(() => { // should asynchronously be after setProducts so that the products can load on screen first before the more taxing request of getting the product count
          countProducts(window.location.search).then(res => setCount(res.count))
      })
  }, [page, queryParams]);

  const [productsClassName, setProductsClassName] = useState('products');
  const [sideClassName, setSideClassName] = useState('shop-sidebar');
  const [collapsed, setCollapsed] = useState(false)
  const smallScreen = useMediaQuery({
    query: '(max-width: 584px)'
  });
  const smallHeight = useMediaQuery({
    query: '(max-height: 655px)'
  })
  useEffect(() => {
    if (smallScreen || smallHeight) {
      setCollapsed(true);
      collapseSidebar();
    }
  }, [smallScreen]);
  const collapseSidebar = (collapsed) => {
    if (!collapsed) {
      setProductsClassName('products-after-collapse');
      setSideClassName('shop-sidebar-after-collapse');
      setCollapsed(true)
    } else {
      setProductsClassName('products');
      setSideClassName('shop-sidebar');
      setCollapsed(false);
    }
  }

  const productsRef = useRef();
  return (
    <div className={styles.container} >
      
      {animationLoading ? 
        <div style={{height: '100vh' }} >
          <img src={animation} style={{position: 'fixed', top: '20%', left: '39%', filter: 'drop-shadow(30px 10px 4px #87A887)', width: '20%', marginLeft: '30px', marginTop: '80px', zIndex: 999 }} alt='currently-loading' />
        </div>
        : 
        null
      }
      
      <div className={styles['top-fading']} style={!navVisible ? {top: '63px'} : null} onClick={() => collapseSidebar(collapsed)} >
        <div className={styles['top-fading-inner']} >
          <div style={{fontSize: '15.5px', marginRight: '19px', marginTop: '2px' }} >categories / filters</div>
          <Collapse
          width={15}
          lineHeight={2}
          lineSpacing={2}
          borderRadius={23}
          active={!collapsed}
          color={'gray'}
          padding={'0px'}
          />
        </div>
        
      </div>

      <div className={styles[sideClassName]} style={!navVisible ? {top: '63px'} : null}>
        <ShopSidebar 
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          collapseSidebar={collapseSidebar}
          collapsed={collapsed}
          categories={[
          {name: 'Tops', tier: 1, subCategories: [
            {name: 'T-shirts', tier: 2, subCategories: []},
            {name: 'Sweaters', tier: 2, subCategories: [
              {name: 'Knit Sweaters', tier: 3, subCategories: []},
              {name: 'Hoodies', tier: 3, subCategories: []},
              {name: 'Cardigans', tier: 3, subCategories: []},
              {name: 'Crewnecks', tier: 3, subCategories: []},
            ]}
          ]},

          {name: 'Bottoms', tier: 1, subCategories: [
            {name: 'Jeans', tier: 2, subCategories: []},
            {name: 'Shorts', tier: 2, subCategories: []},
            {name: 'Chinos', tier: 2, subCategories: []},
            {name: 'SweatPants', tier: 2, subCategories: []},
          ]},

          {name: 'Accessories', tier: 1, subCategories: [
            {name: 'Hats', tier: 2, subCategories: []},
            {name: 'Jewelry', tier: 2, subCategories: [
              {name: 'Bracelets', tier: 3, subCategories: []},
              {name: 'Necklaces', tier: 3, subCategories: []},
              {name: 'Rings', tier: 3, subCategories: []},
            ]}
          ]},

          {name: 'Footwear', tier: 1, subCategories: [
            {name: 'Sneakers', tier: 2, subCategories: []},
            {name: 'Boots', tier: 2, subCategories: []},
          ]},

          {name: 'Outerwear', tier: 1, subCategories: [
            {name: 'Puffers', tier: 2, subCategories: []},
            {name: 'Fleece', tier: 2, subCategories: []},
            {name: 'WindBreakers', tier: 2, subCategories: []},
            {name: 'Vests', tier: 2, subCategories: []},
          ]},
        ]} />
      </div>

      <div className={styles[productsClassName]} >
        <div ref={productsRef}  style={(!collapsed && smallScreen) || (!collapsed && smallHeight) ? {transform: 'scaleY(0.0)', overflow: 'hidden', transition: 'height 0s 5s'} : null}>
          <Products products={products} />
        </div>
        <div style={!collapsed ? {marginRight: '17%'} : null} >
          <PageNavigator 
            queryParams={queryParams}
            setQueryParams={setQueryParams} 
            page={page} 
            pageLimit={pageLimit} 
            count={count}
          />
        </div>
      </div>

    </div>
  );
}

export default Shop;