import React from "react";
import styles from './Login.module.css'
import { Link, useLocation } from "react-router-dom";
import Form from "../components/Login/Form";
import sideLeaves from '../image/side-leaves-min.webp';
import sideLeavesRight from '../image/side-leaves-right-min.webp';

function Login() {

  const location = useLocation();

  return (
    <div className={styles.container} >

      <img src={sideLeaves} className={styles['side-leaves']} style={{left: '0px' }} />
      <img src={sideLeavesRight} className={styles['side-leaves']} style={{right: '0px' }} />

      <Form fromCheckout={location.state?.fromCheckout} />
      <Link to={'/register'} className={styles.link} >create account</Link>
      <Link to={'/user-password-reset'} className={styles.link} >forgot password?</Link>
    
    </div>
  );

}

export default Login;