import React from "react";
import styles from './Products.module.css';
import Product from './Product';
import { useSearchParams } from "react-router-dom";
import OrderOptions from "./OrderOptions";

function Products( {products} ) {

  const [queryParams, setQueryParams] = useSearchParams({});

  return (
    <>
      <div className={styles['info-category']} >
        <div style={{display: 'flex', alignItems: 'center', gap: '15px' }} >
          {queryParams.get('category') ? (
            <div className={styles['category-label']} style={{}}>
              Category: {queryParams.get("category")}
            </div>
            )
            :
            null
          }
          <OrderOptions queryParams={queryParams} setQueryParams={setQueryParams} />
        </div>
      </div>
      <ul id='products' className={styles.container} >
        {products.map(prod => {
          return <li key={prod.product_id} className={styles.item} ><Product product={prod} /></li>
        })}
        <li className={styles.item} ></li>
      </ul>
    </>
  );
}

export default Products;