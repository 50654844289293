import React from "react";

function PrivacyPolicy() {
  return (
    <div style={{textAlign: 'left', margin: '20px' }} >
      <p>
        <span ><strong>Privacy Policy</strong></span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >Updated January 23, 2023</span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >
          Greenhauls Inc. (&ldquo;Greenhauls&rdquo; or &ldquo;we&rdquo; or
          &ldquo;us&rdquo;) is devoted to the relationship of trust we have with
          you, our customer. In addition, we value the importance of your
          privacy. When your information is collected by us, we aim to use it to
          better your experience. We aspire to be transparent about the use and
          collection of your personal information so you have control over which
          information you share with us. We do not sell your information to
          third parties and would not do so without your permission. We use a
          selection of security technologies and procedures to help protect your
          information from unauthorized access, use or disclosure.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >
          This privacy policy governs the personal information and other
          information of yours that we may collect and use in correlation with
          the use of a specific website, application or other interactive
          service (&ldquo;Services&rdquo;) provided by Greenhauls. As a customer
          with Greenhauls you may be asked for information that may include your
          name, address, email address or telephone number when creating an
          account or making a purchase with us.This personal information is
          collected and used by us.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>CATEGORIES</strong>
      </p>
      <p>
        <span >
          There are different categories of personal information that we may
          collect from you:&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >
          Contact personal information, which may include your name, mailing
          address, email address or telephone number. We will ask for a name and
          email address when you create an account with us. You may provide
          additional information if you choose to personalize your account. An
          example of this is adding items to a wish list. We will also ask for
          other personal information to make suggestions for your shopping
          needs.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >
          Transactional personal information for all purchases and returns. This
          includes items such as credit or debit card data, mailing and email
          addresses, telephone number, IP or device addresses or identifiers,
          and other related information including receipts, confirmations,
          shipping, billing, adjustments, loyalty programs, and more. This
          personal information can be about you or others.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >
          Activity or Qualification personal information includes giving
          personal information to meet an age or experience limit, or indicating
          your likes or dislikes (e.g.: indicating a like via a &ldquo;thumbs
          up/thumbs down&rdquo; type of icon). If one of your activities posts
          personal information in areas available to the public, that
          information becomes public. Please be cautious about what you post
          because it becomes available to anyone. Personal information you
          voluntarily add into a message, photo, or video provided to us may
          also be collected.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >
          Community personal information that you post on our website,
          application, or an interactive community, such as social media sites,
          where such information is accessible by other members or is public
          (e.g. posting a product review or creating a tag). Personal
          information about others may include a photo submitted to us
          containing an image of a friend, or someone else&rsquo;s personal
          information. Personal information about someone else should not be
          submitted without consent to this privacy policy or otherwise
          authorized.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >
          Sensitive Data personal information may account for financial related
          information, geo-location data or other stored information including
          contacts, photos, videos.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >
          Promotional personal information and surveys is information relating
          to a poll, survey, contest or other promotional activity presented or
          sponsored by us alone or with others.This personal information could
          include but is not limited to: name, gender, email, and mailing
          address. The following are examples of categories of personal
          information we may collect from other sources or indirectly from you:
          Personal information from our previous records can be personal
          information you gave us for an activity that we use for another. For
          example, re-using data from a previous transaction to pre-populate
          fields in a later transaction to make the ordering process easier.
          Personal information we obtain from public sources, to the extent
          allowed by law, including: websites, mobile applications, and other
          information others may have about you.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >
          Indirect transactional personal information, inclusive of time, date,
          and items purchased, may be collected for cases such as fraud
          prevention or authentication.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <span >
          Social media personal information is shared with us if you connect
          with us on social media or utilize social media functions found on our
          channels. The social media network may share personal information with
          us in conformance with the social media network&rsquo;s privacy policy
          or other terms. Metadata, embedded data from digital items like
          pictures, has personal information we may collect and
          use.&nbsp;&nbsp;&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>COOKIES</strong>
      </p>
      <p>
        <span >
          Upon visiting our website, greenhauls.com, different kinds of
          technologies may be used for reasons including, but not limited to,
          improving your shopping experience by reducing the amount of
          information required at checkout. Some examples of these technologies
          include: Cookies, which can be used to improve your experience by
          enhancing our website design, services, or promotions. They can
          recognize you or your device and collect some personal information.
          &ldquo;Session&rdquo; cookies allow us to collect information about
          your activities during a browsing session but are set to be deleted at
          the session&rsquo;s conclusion. &ldquo;Functional/Persistent&rdquo;
          cookies are set to expire in a number of days.
          &ldquo;Performance&rdquo; cookies are similar to persistent cookies
          but collect performance and design data. It may be possible to set
          your browser to reject cookies, however some functionalities of the
          website may be slower or unavailable to you.Tracking pixels, tags, web
          beacons, clear GIFs, API calls, SDKs, JavaScript are items that can
          function in a similar way to cookies that help to track activity by
          collecting information about your interaction with us. Identifiers,
          such as an IP Address, a Media Access Control address, or other
          identifiers that may pertain to you, devices you use, or identifiers
          used by ads, advertisers, networks, or other third parties. Some
          examples of personal information that may be collected by technologies
          we use include: browser type and version, accessing device type,
          operating system, Internet Service Provider, IP address, Media Access
          Control address, telephone number, hardware/software types,
          authentication data, demographic data, search history, and other data
          that may create a unique &ldquo;fingerprint&rdquo; of your device.
          Domain name or page you are coming from, link, ad, website or source
          that referred you, time and date stamps, how long you stay on the
          website and if you are a returning visitor, where you visit and what
          you do on our website, contents of your shopping cart and purchased
          items details, clickstream data, patterns, general location data or
          precise location data, and user history on app usage.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>HOW WE USE PERSONAL INFORMATION</strong>
      </p>
      <p>
        <span >
          To provide products and services. This includes providing products and
          services you purchase and request including, payment and transaction
          processing, order fulfillment, maintenance of customer accounts,
          providing promotions, events and other offers. Personal information is
          also used to improve and develop new products, features and services.
          Personal information is used to send you communications about
          promotions, events, products, services and offers we think may be of
          interest to you. Personal information is used to personalize online
          content and experiences. This can include providing recommendations
          and reminders, retaining user preferences, presenting content in the
          most effective way for you to access our online services. We may link
          browsers and devices you use to access our online services to help us
          recognize you as a customer or visitor and provide you with a
          consistent personalized experience when using different browsers or
          devices when interacting with us. We may use personal information to
          conduct targeted ads and analyze the performance of our advertising
          and marketing efforts based on your online activities and your
          interaction with our advertising and marketing. We may use personal
          information to create and identify audiences that are most likely to
          interact with our ads based on information we have already collected
          about your interests. Personal information is used in compliance with
          legal and regulatory obligations which may include maintaining records
          of your privacy preferences and choices, protecting the rights of
          others and our own, and fulfilling corporate obligations.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>DISCLOSURE OF PERSONAL INFORMATION</strong>
      </p>
      <p>
        <span >
          Personal information may be disclosed to third parties engaged in a
          proposed or actual business transaction. Personal information may be
          shared with affiliates as necessary for the proper management of the
          Greenhauls business. Personal information may be used by service
          providers for purposes that can include: shipping, authentication,
          credit card processing, email distribution, market research,
          information technology, and data hosting services. Personal
          information may be disclosed to analytics providers (i.e. Google
          Analytics) to collect traffic data. Personal information may be
          disclosed as required or permitted by law, to enforce or preserve our
          rights, meet legal, regulatory, security, fraud prevention,
          authentication or verification purposes.
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>PROTECTION OF YOUR PERSONAL INFORMATION</strong>
      </p>
      <p>
        <span >
          Your personal information will be maintained on our servers and
          accessible only by employees, or representatives who require access as
          noted by their job duties. We use reasonable security measures to
          protect your personal information.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>CALIFORNIA RESIDENTS</strong>
      </p>
      <p>
        <span >
          California&rsquo;s Shine the Light Law permits you to request certain
          information regarding our disclosure of personal information to third
          parties for their direct marketing purposes in the preceding calendar
          year and the categories of information disclosed to them.&nbsp;
        </span>
      </p>
      <p>&nbsp;</p>
      <p>
        <strong>MINORS</strong>
      </p>
      <p>
        <span >
          These Services are not intended for use by minors. We do not knowingly
          collect personal information directly from children without their
          parental verified consent.&nbsp;
        </span>
      </p>
    </div>
  );
}


export default PrivacyPolicy;