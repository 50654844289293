import React from "react";
import Form from "../components/Register/Form";
import styles from './Register.module.css';
import sideLeaves from '../image/side-leaves-min.webp';
import sideLeavesRight from '../image/side-leaves-right-min.webp';

function Register() {

  return (
    <div className={styles.container} >

      <img src={sideLeaves} className={styles['side-leaves']} style={{left: '0px' }} />
      <img src={sideLeavesRight} className={styles['side-leaves']} style={{right: '0px' }} />

      <Form />
      
    </div>
  );
}

export default Register;