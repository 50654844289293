import React from "react";

function ShippingReturns() {
  return (
    <div style={{margin: '20px', textAlign: 'left', minHeight: '100vh' }} >
      <p ><strong>HOW WE PROTECT YOUR HAUL</strong></p>
      <p>
        Greenhauls is committed to providing excellent customer service. We want
        our customers to feel confident when placing orders. All orders are
        eligible for a size exchange as long as the desired size is available.
        If the product received is not &ldquo;as described&rdquo; or determined
        to be damaged upon arrival to its destination, the item can be refunded
        within 7 days of receipt. Please note, photo substantiation must be
        provided in order for a successful refund. If the parcel is determined
        to be lost in transit, please email{" "}
        <a href="mailto:support@greenhauls.com">support@greenhauls.com</a> for
        assistance.{" "}
      </p>
    </div>
  );
}

export default ShippingReturns;
