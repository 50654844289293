import React from "react";

function TermsConditions() {
  return (
    <div style={{textAlign: 'left', margin: '20px' }} >
      <p ><strong>Terms and Conditions</strong></p>
      <p>
        <br />
        <br />
      </p>
      <p>Updated: January 21, 2023</p>
      <p>
        Welcome to the Greenhauls Inc. Website. Use of this website and any
        additional applications (combined, the &ldquo;Site&rdquo;). Please
        review the following terms and conditions of use, which regulates your
        access and use of this website and any additional applications (the
        &ldquo;Agreement&rdquo;). By visiting the site, you acknowledge that you
        have read and agree to follow and be bound by the Agreement. We reserve
        the right to update or modify this Agreement at any time without notice.
        It is recommended that you review this agreement each time prior to your
        use of this website. If you do not agree to these terms, you should not
        use the Site.
      </p>
      <p>
        <strong>SITE CONTENTS</strong>
        <br />
        Unless otherwise noted, the Site, and all materials on the site, (the
        &ldquo;Contents&rdquo;) are owned, controlled or licensed by Greenhauls
        Inc. or its merchandise suppliers. The Site and its Contents are
        intended solely for personal, non-commercial use. You do not own any
        right, title or interest in any downloaded or copied Content.You may not
        reproduce, publish, distribute, modify, create, display any works
        derived from the Site or its Contents, nor may you sell or exploit any
        of the Contents or the Site.
      </p>
      <p>
        <strong>USER FEEDBACK</strong>
      </p>
      <p>
        Greenhauls Inc. welcomes your comments regarding our products and
        services. Greenhauls Inc. does not allow acceptance or consideration of
        creative ideas, suggestions, or other materials other than what was
        requested. If you send specific submissions with or without a request
        from us (inclusively &ldquo;Comments&rdquo;), you agree that Greenhauls
        Inc. may, at any time, edit, copy, publish, distribute, and otherwise
        use any Comments in any medium without restriction. Greenhauls Inc. is
        under no obligation to maintain Comments in confidence, compensate
        Comments, or respond to Comments and has the right to edit and remove
        any Comments. You are solely responsible for any Comments you make and
        Greenhauls Inc. takes no responsibility and assumes no liability for any
        comments posted by you or any third party.
      </p>
      <p>
        <strong>PERSONAL INFORMATION</strong>
      </p>
      <p>
        Your submission of personal information through the Site is regulated by
        our Privacy Policy. This Agreement incorporates the terms and conditions
        as discussed in the Privacy Policy. (MIGHT BE HELPFUL TO INCLUDE A
        DIRECT LINK TO PRIVACY POLICY HERE)
      </p>
      <p>
        <strong>INDEMNIFICATION</strong>
      </p>
      <p>
        You agree to hold Greenhauls Inc. harmless from and against any and all
        expenses, damages, costs, and claims, including attorneys&rsquo; fees
        arising from or related to your use of the Site and or your breach of
        any provision of the Agreement.{" "}
      </p>
      <p>
        <strong>CHOICE OF LAW</strong>
      </p>
      <p>
        This Agreement shall be exacted upon in accordance with the laws of the
        State of California. This Agreement accounts for the entire agreement
        between us and your use of the Site.&nbsp;
      </p>
      <p>
        <strong>TERMINATION</strong>
      </p>
      <p>
        These terms are effective unless terminated by either you or Greenhauls
        Inc. You may terminate this Agreement at any time. Greenhauls Inc. may
        terminate this agreement at any time without notice and may deny you
        access to the Site if you fail to comply in accordance with any term or
        provision of the Agreement.
      </p>
    </div>
  );
}

export default TermsConditions;