import React from "react";
import styles from './SiteFooter.module.css';
import { Link } from "react-router-dom";

function SiteFooter() {
  return (
    <>
    <div className={styles.container} >

      <div className={styles.background} ></div>

      <div className={styles.links} >
        <Link to='/privacy-policy' className={styles.link} >Privacy Policy</Link>
        <Link to='/FAQ' className={styles.link} >FAQs</Link>
        <Link to='/shipping-returns' className={styles.link} >Returns/ Shipping</Link> 
        <Link to='/terms-conditions' className={styles.link} >Terms & Conditions</Link>
      </div>
    </div>
    </>
  );
}

export default SiteFooter;
