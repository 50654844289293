import React from "react";

function Wishlist() {
  return (
    <div style={{height: '100vh' }} >
      wishlist
    </div>
  );
}

export default Wishlist;