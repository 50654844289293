import React, { useContext, useEffect, useState } from "react";
import styles from './TopBar.module.css';
import { Link } from 'react-router-dom';
import ItemSearch from "./ItemSearch";
import Navigation from "./Navigation";
import { UserCartContext } from "../context/AppContext";
import { useMediaQuery } from 'react-responsive';
import Userfront from '@userfront/react';
import { NavVisibleContext } from '../context/AppContext';
import gweenHaulsGwass from '../image/gweenhaulsgwass.svg';
import wishlist from '../image/wishlist.png';
import truck from '../image/truck.png';
import userAccount from '../image/user.png';
import glass from '../image/src.png';
Userfront.init("rbvzmr6b");
const image = require('../image/logo.png');

function TopBar() {
  
  const { navVisible } = useContext(NavVisibleContext);

  const smallScreen = useMediaQuery({
    query: '(max-width: 582px)'
  });

  const {userCart} = useContext(UserCartContext);

  const [smallScreenSrc, setSmallScreenSrc] = useState(false)
  const smallScreenSrcClick = () => {
    setSmallScreenSrc((cur) => !cur);
    setTimeout(() => {
      document.getElementById('src').focus();
    }, 50);
  }

  return (
    <>
      <div className={styles.container} style={!navVisible ? {borderBottom: 'solid #d5d5d5 1px'} : null} >
        <div className={styles['items-left-group']} >
        <div >
            {smallScreen && smallScreenSrc ?
              <div style={smallScreenSrc && smallScreen ? {display: 'flex', width: '100vw', flexDirection: 'column'} : {display: 'none'}} >
                <ItemSearch setSmallScreenSrc={setSmallScreenSrc} />
              </div>
              :
              <Link to={'/'} >
                <h1 className={styles['logo-container']} >
                  <img src={gweenHaulsGwass} className={styles.logo} alt='Greenhauls' />
                </h1>
              </Link>
            }
          </div>
          <div className={styles.src} >
            {!smallScreen ?
              <ItemSearch />
              :
              <div onClick={smallScreenSrcClick} style={{display: 'flex' }} >
                <img src={glass} className={styles.icon} style={smallScreenSrc ? {display: 'none'} : null} />
              </div>
            }
          </div>
        </div>

        {!smallScreenSrc ?
          <div className={styles['items-right-group']} >

            {Userfront.accessToken() ?
              <Link to={'/my-account'} className={styles.link} >
                <img src={userAccount} className={styles.icon} />
              </Link>
              :
              <Link to={'/login'} className={styles.link} >
                <img src={userAccount} className={styles.icon} />
              </Link>
            }

            <Link to={'/wishlist'} className={styles.link} >
              <img src={wishlist} className={styles.icon} style={{height: '17px' }} />
            </Link>

            <Link to={'/cart'} className={styles.link} >
              {Array.isArray(userCart) && userCart.length > 0 ? <div className={styles['item-count']} >{userCart.length}</div> : null}
              <img src={truck} className={styles.carticon} alt='user cart truck' />
              <br/>
            </Link>

          </div>
          :
          null
        }

      </div>

    </>
  );
}

export default TopBar;