import React, { useContext, useState, useEffect } from "react";
import styles from "./TopBarChanged.module.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { UserCartContext } from "../context/AppContext";
import { useMediaQuery } from "react-responsive";
import logo from '../image/logo-light-small.png';
import wishlist from '../image/wishlist.png';
import userAccount from '../image/user.png';
import truck from '../image/truck.png';
import glass from '../image/src.png';
import ShopDropwdownChanged from "./ShopDropdownChanged";
import { Collapse } from "react-burgers";
import Userfront from "@userfront/react";
Userfront.init("rbvzmr6b");

function TopBarChanged() {
  const [srcBarOpen, setSrcBarOpen] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  useEffect(() => {
    const closeDropdown = (e) => {
      const dropdownElement = document.getElementById('shop-dropdown');
      if (!dropdownElement.contains(e.target)) {
        setDropdownOpen(false);
      }
    }

    if (dropdownOpen) {
      setTimeout(() => {
        document.addEventListener('click', closeDropdown)
      }, 50);
    } else {
      document.removeEventListener('click', closeDropdown)
    }

    return () => document.removeEventListener('click', closeDropdown)
  }, [dropdownOpen]);

  let location = useLocation();
  useEffect(() => {
    setDropdownOpen(false);
  }, [location]);

  const smallScreen = useMediaQuery({
    query: '(max-width: 900px)'
  });

  const { userCart } = useContext(UserCartContext);

  const [smallScreenSrc, setSmallScreenSrc] = useState(false);
  const smallScreenSrcClick = () => {
    setSmallScreenSrc((cur) => !cur);
    setTimeout(() => {
      document.getElementById("src").focus();
    }, 50);
  };

  const srcHandler = (e) => {
    setSrcBarOpen(true);
    setTimeout(() => {
      document.getElementById("src-bar").focus();
      document
        .getElementById("src-bar")
        .addEventListener("blur", () => setSrcBarOpen(false));
    }, 50);
  };
  const navigate = useNavigate();
  const srcSubmit = (event) => {
    event.preventDefault();
    const array = event.target["src-bar"].value.split(" ");
    const str = array.join("+");
    navigate(`/shop?page=1&src=${str}`);
    event.target["src-bar"].value = "";
  };

  const dropdownHandler = (e) => {
    if (e.target.id === "shop-men") {
      setDropdownOpen("Men");
    } else if (e.target.id === "shop-women") {
      setDropdownOpen("Women");
    }
  };

  
    return (
      <div className={styles.container}>

        {!smallScreen ?
          <div className={styles["sidegroup-container"]}>
            <div id="shop-men" onClick={dropdownHandler} className={styles.link}>
              Men
            </div>

            <div id="shop-women" onClick={dropdownHandler} className={styles.link} >
              Women
            </div>

            <div className={styles.link}>Trending</div>

            <div>
              <Link className={styles.link} to={`/shop?page=1&order=created_at`}>
                New Arrivals
              </Link>
            </div>

            {!srcBarOpen ? (
              <div
                onClick={srcHandler}
                style={{ display: "flex", alignItems: "center" }}
              >
                <img
                  src={glass}
                  className={styles.icon}
                  style={{ width: "20px" }}
                />
              </div>
            ) : (
              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <form
                  id="form-search"
                  onSubmit={srcSubmit}
                  style={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center' }}
                >
                  <input
                    id="src-bar"
                    type="search"
                    placeholder="search"
                    style={{ width: '335px', height: '25px' }}
                  />
                  <img
                    src={glass}
                    style={{ width: '15px', position: 'absolute', marginRight: '5px', pointerEvents: 'none' }}
                  />
                </form>
              </div>
            )}
          </div>
          :
          <div onClick={() => {
            if (dropdownOpen) {
              setDropdownOpen(false);
            } else {
              setDropdownOpen('menu');
            }
          }} >
            <Collapse
              width={25}
              lineHeight={3}
              lineSpacing={3}
              borderRadius={5}
              active={dropdownOpen}
              color={'#333'}
              padding={'0px'}
            />
          </div>
        }

        {dropdownOpen ? (
          <div className={styles.dropdown}>
            <ShopDropwdownChanged
              dropdownOpen={dropdownOpen}
              setDropdownOpen={setDropdownOpen}
              smallScreen={smallScreen}
            />
          </div>
        ) : null}

        <div className={styles["logo-container"]}>
          <Link to={"/"}>
            <img src={logo} className={styles.logo} />
          </Link>
        </div>

        <div className={styles["sidegroup-container"]}>
          {!smallScreen ? 
            <>
              <div className={styles.link}>Blog</div> 

              <div >
                {Userfront.accessToken() ?
                  <Link className={styles.link} to={"/my-account"}>
                    My Account <img src={userAccount} style={{ width: "15px" }} />
                  </Link>
                  :
                  <Link to={'/login'} className={styles.link} >
                    Login <img src={userAccount} style={{ width: "15px" }} />
                  </Link>
                }
              </div>

              <div>
                <Link className={styles.link} to={"/wishlist"}>
                  My Wishlist <img src={wishlist} style={{ width: "15px" }} />
                </Link>
              </div>
            </>
            : 
            null
          }

          <div>
            <Link to={"/cart"}>
              {Array.isArray(userCart) && userCart.length > 0 ? (
                <div className={styles["item-count"]}>{userCart.length}</div>
              ) : null}
              <img src={truck} className={styles.truck} />
              <br />
            </Link>
          </div>

        </div>
        
      </div>
    );
}

export default TopBarChanged;
