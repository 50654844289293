import React, { useState, useEffect }  from "react";
import styles from './ShopDropdownChanged.module.css';
import { Link, useNavigate } from "react-router-dom";
import { listSuppliers } from "../utils.js/api";
import glass from '../image/src.png';
import arrow from '../image/back-arrow.png';
import Userfront from "@userfront/react";
Userfront.init("rbvzmr6b");

function ShopDropwdownChanged ( { dropdownOpen, setDropdownOpen, smallScreen } ) {
  
  const gen = dropdownOpen;
  
  const [suppliers, setSuppliers] = useState([]);
  useEffect(() => {
    if (dropdownOpen && suppliers.length === 0) {
      listSuppliers()
        .then(setSuppliers)
    }  
  }, [dropdownOpen])

  const [thrifted, setThrifted] = useState('') // there are 3 possible options '&thrifted=only' returns only thrift, '&thrifted=none' returns no thrift, and empty includes both

  const [srcValue, setSrcValue] = useState('');
  const navigate = useNavigate();
  const srcSubmit = (event) => {
    event.preventDefault();
    
    const array = document.getElementById('src-mobile').value.split(' ')
    const str = array.join("+");
    navigate(`/shop?page=1&src=${str}`);
    setSrcValue('');
  };

  const menuGender = (
    <div id='shop-dropdown' className={styles.container} >

        <img src={arrow} className={styles['back-arrow']} onClick={() => {
          console.log(dropdownOpen)
          if ((smallScreen && dropdownOpen === 'Men') || (smallScreen && dropdownOpen === 'Women')) {
            setDropdownOpen('menu');
          } else {
            setDropdownOpen(false);
          }
        }} />
        <h2 className={styles.gen} >{dropdownOpen}</h2>
        <hr className={styles.hr} />
        
        <div >
          <div >
            include thrift / upcycled clothing?
          </div>
          <div >
              <button id='thrifted-combined' onClick={() => setThrifted('')} className={thrifted === '' ? styles['thrifted-checked'] : styles['thrifted-unchecked'] } >yes</button>
              |
              <button id='thrifted-false' onClick={() => setThrifted('&thrifted=none')} className={thrifted === '&thrifted=none' ? styles['thrifted-checked'] : styles['thrifted-unchecked']} >no</button>
              |
              <button id='thrifted-true' onClick={() => setThrifted('&thrifted=only')} className={thrifted === '&thrifted=only' ? styles['thrifted-checked'] : styles['thrifted-unchecked'] } >only</button>
          </div>
        </div>

        <div className={styles.categories} >
            <h3 className={styles.h3} >Categories</h3>
            <hr className={styles.hr} />
            <Link to={`/shop?page=1&gender=${gen.toLowerCase()}${thrifted}`} className={styles.link} onClick={() => setDropdownOpen(false)} >All</Link>
            <Link to={`/shop?category=Tops&page=1&gender=${gen.toLowerCase()}${thrifted}`} className={styles.link} onClick={() => setDropdownOpen(false)} >Tops</Link>
            <Link to={`/shop?category=Bottoms&page=1&gender=${gen.toLowerCase()}${thrifted}`}  className={styles.link} onClick={() => setDropdownOpen(false)} >Bottoms</Link>
            <Link to={`/shop?category=Accessories&page=1&gender=${gen.toLowerCase()}${thrifted}`} className={styles.link} onClick={() => setDropdownOpen(false)} >Accessories</Link>
            <Link to={`/shop?category=Footwear&page=1&gender=${gen.toLowerCase()}${thrifted}`} className={styles.link} onClick={() => setDropdownOpen(false)} >Footwear</Link>
            <Link to={`/shop?category=Outerwear&page=1&gender=${gen.toLowerCase()}${thrifted}`} className={styles.link} onClick={() => setDropdownOpen(false)} >Outerwear</Link>
        </div>

        <div className={styles.brand} >
            <h3 className={styles.h3} >Brands</h3>
            <hr className={styles.hr} />
            <ul className={styles['brand-scroll']} >
              {suppliers.map((supplier) => {
                return <li ><Link to={`/shop?page=1&supplierId=${supplier['supplier_id']}${thrifted}`} onClick={() => setDropdownOpen(false)} className={styles.link} style={{paddingRight: '8px' }} >{supplier.name}, </Link></li>
              })}
            </ul>
        </div>

    </div>
  );
  
  if (!smallScreen || gen === 'Men' || gen === 'Women') {
    return (
      <>
        {menuGender}
      </>
    )
  } else {
    return (
      <div id='shop-dropdown' className={styles.container} >
        <div className={styles['small-screen-menu-group']} >
          <form onSubmit={srcSubmit} style={{position: 'relative', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: '15px' }} >
            <input id='src-mobile' type='search' onChange={(e) => setSrcValue(e.target.value)} style={{height: '20px', width: '170px' }} placeholder="search" />
            <img src={glass} style={{position: 'absolute', width: '15px', marginRight: '5px' }} />
          </form>

          <div onClick={() => setDropdownOpen('Men')} className={styles['small-screen-menu-div']} >
            Men
          </div>

          <div onClick={() => setDropdownOpen('Women')} className={styles['small-screen-menu-div']} >
            Women
          </div>

          <div className={styles['small-screen-menu-div']} >
            Trending
          </div>

          <div className={styles['small-screen-menu-div']} >
            <Link to='/shop?page=1&order=created_at' onClick={() => setDropdownOpen(false)} >
              New Arrivals
            </Link>
          </div>
        </div>

        <hr className={styles.hr} />

        <div className={styles['small-screen-menu-group']} >
          <div className={styles['small-screen-menu-div']} >
            {Userfront.accessToken() ?
              <Link to='/my-account' onClick={() => setDropdownOpen(false)} >
                My Account
              </Link>
              :
              <Link to={'/login'} onClick={() => setDropdownOpen(false)} >
                Login
              </Link>
            }
          </div>

          <div className={styles['small-screen-menu-div']} >
            <Link to='/wishlist' onClick={() => setDropdownOpen(false)} >
              My Wishlist
            </Link>
          </div>

          <div className={styles['small-screen-menu-div']} >
            Blog
          </div>
        </div>
      </div>
    )
  }
}

export default ShopDropwdownChanged;