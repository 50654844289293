import React, { useContext, useEffect, useState } from "react";
import '../App.css';
import styles from './ShopProduct.module.css';
import { UserCartContext } from "../context/AppContext";
import { readProduct, readSupplier, userfrontUpdateAtc } from "../utils.js/api";
import { Link, useParams } from "react-router-dom";
import SizeColor from '../components/ShopProduct/SizeColor';
import { newCookie, retrieveCartItems } from "../utils.js/cookieHelper";
import Userfront from '@userfront/core';
Userfront.init("rbvzmr6b");

function ShopProduct() {

  const { setUserCart } = useContext(UserCartContext);

  const params = useParams();
  const [id, setId] = useState(params.product_id.slice(3));
  const [selectedProduct, setSelectedProduct] = useState({});
  const [supplier, setSupplier] = useState({});
  const [displayedImage, setDisplayedImage] = useState(null)

  const [sizeColorQty, setSizeColorQty] = useState([{
    color: null,
    image: null,
    sizes: [
      ['', 0],
    ],
  }]);

  const [curSelection, setCurSelection] = useState({
    colorSelected: sizeColorQty[0],
    sizeSelected: null,
    qtySelected: 1
  })

  const [sizeUnselectedError, setSizeUnselectedError] = useState(false);

  useEffect(() => {
    readProduct(id)
      .then((res) => {
        setSelectedProduct(res);
        setDisplayedImage(res.image);
        setSizeColorQty(res.size_color_qty.size_color_qty);
        setCurSelection((cur) => {
          return {...cur, colorSelected: res.size_color_qty.size_color_qty[0]}
        })
        readSupplier(res.supplier_id).then(setSupplier)
      })
  }, [id]);
  
  const [atcDisabled, setAtcDisabled] = useState(false);

  const atc = async () => {

  setAtcDisabled(true);

  if (!curSelection.sizeSelected) {
    setSizeUnselectedError(true);
    setAtcDisabled(false);
    return;
  }

    const item = {
      id: id,
      cartItemId: `cartItem${Date.now()}`,
      name: selectedProduct['product_name'],
      attributes: curSelection,
      price: selectedProduct.price,
      supplier: selectedProduct['supplier_id']
    }

    if (Userfront.accessToken()) {
      
      userfrontUpdateAtc(item)
        .then((res) => {
          setUserCart(res.data.userCart)
          setAtcDisabled(false)
        })

    } else {

      newCookie(`cartItem${Date.now()}`, JSON.stringify(item), 3);
      setUserCart(
        retrieveCartItems()
      )
      setAtcDisabled(false);
    }

  }

  return (
    <div className={styles.container} >
      
      <div className={styles['container-top']} >
        <div className={styles['alt-images-container']}>alt images</div>

        <div className={styles['main-image-container']} >
          <img 
            className={styles['main-image']}
            src={displayedImage}
            alt='prod'
          />
        </div>
        
        <div className={styles['right-container']} >
          <div className={styles['brand-container']} >
            <Link to={`/brands/${selectedProduct.supplier_id}`} className={styles.brand} >{supplier.name}</Link>
          </div>
          <div className={styles.name} >
            {selectedProduct.product_name}
          </div>
          <div className={styles.price} >
            {selectedProduct.price}
          </div>

          <hr style={{width: '100%'}} />
          <SizeColor 
            sizeColorQty={sizeColorQty} 
            setDisplayedImage={setDisplayedImage} 
            id={id}
            curSelection={curSelection}
            setCurSelection={setCurSelection}
            setSizeUnselectedError={setSizeUnselectedError}
          />
          {sizeUnselectedError ? <p>please select a size first</p> : null}
          <button onClick={atc} className='btn' style={{width: '100%', fontWeight: 'bold', fontSize: '1.15em' }} disabled={atcDisabled} >add to cart</button>
          
        </div>
      </div>
      
      <hr style={{width: '90%' }} />

      <div className={styles['container-bottom']} >
        <div >{selectedProduct.description}</div>
      </div>
    </div>
  );
}

export default ShopProduct;